<template>
  <div class="pb-2">
    <l-table
        ref="lTableRef"
        :module-name="MODULE_NAME"
        :table-columns="tableTrashColumns"
        back-to-list-path="settings-categories-list"
        :table-config-options="tableConfigOptions()"
    >
      <template #cell(is_active)="{ data }">
        {{ $t(data.value ? 'Active': 'Inactive') }}
      </template>

      <template #cell(actions)="{ data }">
        <div
            class="text-nowrap d-flex"
            style="gap: 8px"
        >
          <feather-icon
              v-b-tooltip.noninteractive.hover.bottom="$t('Delete')"
              icon="LTrashIcon"
              size="16"
              class="featherIcon cursor-pointer border-dotted"
              @click="remove(data.item)"
          />
          <feather-icon
              v-b-tooltip.noninteractive.hover
              :title="$t('Set to active')"
              icon="LLoadIcon"
              size="24"
              class="featherIcon cursor-pointer border-dotted"
              @click="restoreContact(data.item)"
          />
        </div>
      </template>
      <template #filter>
        {{ '' }}
      </template>
    </l-table>
  </div>
</template>
<script>
import LTable from "@/views/components/LTable/LTable.vue";
import tableConfig from "@/views/settings/catalog/categories/CategoriesConfig";
import {VBTooltip} from "bootstrap-vue";

export default {
  name: 'CategoriesTrashList',
  components: { LTable },
  directives: {
    'b-tooltip': VBTooltip,
  },
  methods: {
    remove(data) {
      this.confirmNotification(this, data, `${this.MODULE_NAME}/del`).then(() => {
        this.refetchData()
      })
    },
    refetchData() {
      this.$refs.lTableRef.refetchData()
    },
    restoreContact(data) {
      this.confirmNotification(this, data, `${this.MODULE_NAME}/setActive`, {
        title: 'Are you sure you want to reactivate this ?',
        text: '',
        confirmButtonText: 'Reactivate',
      })
        .then(() => {
          this.refetchData()
        })
    },
    tableConfigOptions() {
      return {
        endpoint: `${this.MODULE_NAME}/getInActiveCategoryList`,
      };
    },
  },
  setup() {
    const MODULE_NAME = 'settings-catalog-categories'
    const { tableTrashColumns } = tableConfig()
    return {
      tableTrashColumns,
      MODULE_NAME,
    }
  },
};
</script>

<style scoped>

</style>